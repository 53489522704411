<template>
  <div class="start_pack"
       v-loading.fullscreen.lock="fullscreenLoading">
    <!-- 所有弹窗写在这里 -->

    <div class="pop">

      <!-- ============设置所有物品类型============-->
      <el-dialog title="设置所有物品类型"
                 v-model="isShowSetAllGoodsType"
                 width="500px">
        <div class="set_all_goods_type">
          <el-select v-model="allType"
                     @change='setAllGoodsType'
                     placeholder="请选择">
            <el-option v-for="item in goodsType"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </div>
      </el-dialog>
      <!-- ============选择数量============-->
      <el-dialog title="选择数量"
                 v-model="isPopGoods"
                 width="220px">
        <el-input-number v-model="active_num"
                         :min="0"
                         :max="active_max"
                         label="描述文字"></el-input-number>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="cancelNum">取 消</el-button>
            <el-button type="primary"
                       @click="confirmSelecrNum">确 定</el-button>
          </span>
        </template>
      </el-dialog>

      <!-- ============物品说明 ============-->
      <el-dialog title="物品说明"
                 v-model="description_show"
                 width="50%">

        <el-table :data="goodsType"
                  style="width: 100%"
                  border>
          <el-table-column prop="name"
                           label="物品名"
                           width="180">
          </el-table-column>
          <el-table-column prop="info"
                           label="物品说明">
          </el-table-column>
        </el-table>

        <template #footer>
          <span class="dialog-footer">
            <el-button @click="description_show = false">取 消</el-button>
            <el-button type="primary"
                       @click="description_show = false">确 定</el-button>
          </span>
        </template>
      </el-dialog>

      <!-- ============增加/移除物品 ============-->
      <el-dialog v-model="goodsManagement_show"
                 @closed='closeDataReset'
                 width="70%">
        <template #title>
          <div class='dialog_title'>
            <div class="dialog_left">物品管理</div>
            <div class="dialog_right">
              <el-checkbox v-model="resolution">拆分数量</el-checkbox>
            </div>
          </div>
        </template>
        <div class="goodsManagement">
          <el-collapse v-model="popActiveNames"
                       key="popGoods">
            <el-collapse-item :name="index"
                              v-for="(item,index) in dataIten.zxdinfo"
                              :key="'pop'+index">
              <template #title>
                <div @click.stop='stopPop'>
                  <el-checkbox :indeterminate="fatherSelect[index].isIndeterminate"
                               v-model="fatherSelect[index].falg"
                               @change="handleCheckAllChange($event,index)"> {{index}} </el-checkbox>
                </div>
              </template>

              <div class="pop_collapse_view">
                <el-checkbox-group v-model="fatherSelect[index].checkedCities"
                                   @change="handleCheckedCitiesChange($event,index,item)">
                  <div v-for="(item_1,index_1) in item"
                       :key="'pop'+item_1.id"
                       style="display: flex;">
                    <el-checkbox v-for="(item_2,index_2) in item_1.wpinfo"
                                 :label="index+'-'+index_1+'-'+index_2"
                                 :key='"pop"+item_2.id'
                                 @change='selectorCollback($event,item_2,index+"-"+index_1+"-"+index_2)'>{{item_2.name}}
                    </el-checkbox>
                  </div>

                </el-checkbox-group>

              </div>
            </el-collapse-item>
          </el-collapse>
        </div>

        <template #footer>
          <span class="dialog-footer">
            <el-button @click="goodsManagement_show = false">取 消</el-button>
            <el-button type="primary"
                       @click="confirmAddGoods">确 定</el-button>
          </span>
        </template>
      </el-dialog>
    </div>

    <div class="start_pack_view">
      <div class="view_top">
        <div class="top_title">
          <span>物品详情</span>
        </div>
        <el-form :model="activeGoodsInfo"
                 label-width="80px">
          <el-form-item label="中文品名">
            <el-input v-model="activeGoodsInfo.name"></el-input>
          </el-form-item>
          <el-form-item label="英文品名">
            <el-input v-model="activeGoodsInfo.enname"></el-input>
          </el-form-item>
          <el-form-item label="物品单价">
            <el-input v-model="activeGoodsInfo.unitprice"></el-input>
          </el-form-item>
          <el-form-item label="物品数量">
            <el-input v-model="activeGoodsInfo.num"></el-input>
          </el-form-item>
          <el-form-item label="数量单位">
            <el-select v-model="activeGoodsInfo.unit"
                       placeholder="请选择">
              <el-option v-for="(item) in numberUnit"
                         :key="item"
                         :label="item"
                         :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="货物总价">
            <el-input v-model="activeGoodsInfo.totalamount"></el-input>
          </el-form-item>
          <el-form-item label="货币类型">
            <el-select v-model="activeGoodsInfo.currencty"
                       placeholder="请选择">
              <el-option v-for="item in currenctyDate"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="物品类型">
            <el-select v-model="activeGoodsInfo.wptype"
                       placeholder="请选择">
              <el-option v-for="item in goodsType"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="view_bottom">
        <div class="bottom_left">
          <div class="top_title">
            <span>待打包物品</span>
            <el-button size="mini"
                       @click="isShowSetAllGoodsType=true">设置所有物品类型</el-button>
          </div>
          <div class="left_content">
            <el-collapse v-model="activeNames">
              <el-collapse-item :name="index"
                                v-for="(item,index) in dataIten.zxdinfo"
                                :key="index">
                <template #title>
                  <div @click.stop='stopPop'>
                    <span class="collapse_title">{{index}}</span>
                  </div>
                </template>
                <div class="collapse_view">
                  <div v-for="(item_1) in item"
                       :key="item_1.id"
                       style="display: flex;">
                    <div class="collapse_view_item"
                         :class="activeGoodsInfo.id==item_2.id?'activeGoods':''"
                         :style="item_2.wptype==''?'color:#F56C6C':''"
                         @click="tabShowGoodsInfo(item_2)"
                         v-for="(item_2) in item_1.wpinfo"
                         :key="item_2.id">
                      <span>{{item_2.name}}</span>
                    </div>
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse>

          </div>
          <div class="left_bottom">
            <el-button type='primary'
                       @click="showGoodsExplain">查看物品说明</el-button>
          </div>
        </div>
        <div class="bottom_right">
          <div class="top_title">
            <span>装箱信息</span>
          </div>
          <div class="right_content">
            <div class="data_null">

            </div>
            <div class="packing_list">
              <div class="list_item">
                <el-table :data="tableData"
                          :default-expand-all=" true"
                          style="width: 100%">
                  <!-- =================物品数据================= -->
                  <el-table-column type="expand">
                    <template #default="scope">
                      <div class="expand_btn">
                        <el-button size="small"
                                   @click="showGoodsM(scope.row.no)"
                                   circle
                                   icon="el-icon-s-tools"
                                   type="primary"></el-button>
                      </div>
                      <div class="expand_view">

                        <el-table :data="scope.row.wpinfo"
                                  style="width: 100%">
                          <el-table-column label="中文名"
                                           width="80">
                            <template #default="scope">
                              <span v-if="scope.row.name">{{scope.row.name}}</span>
                              <span v-else
                                    style="color:#999">{{'未填写'}}</span>
                            </template>
                          </el-table-column>

                          <el-table-column label="英文名"
                                           width="80">
                            <template #default="scope">
                              <span v-if="scope.row.enname">{{scope.row.enname}}</span>
                              <span v-else
                                    style="color:#999">{{'未填写'}}</span>
                            </template>
                          </el-table-column>

                          <el-table-column label="数量"
                                           width="80">
                            <template #default="scope">
                              <span v-if="scope.row.num">{{scope.row.num}}</span>
                              <span v-else
                                    style="color:#999">{{'未填写'}}</span>
                            </template>
                          </el-table-column>

                          <el-table-column label="单价"
                                           width="80">
                            <template #default="scope">
                              <span v-if="scope.row.unitprice">{{scope.row.unitprice}}元</span>
                              <span v-else
                                    style="color:#999">{{'未填写'}}</span>
                            </template>
                          </el-table-column>

                          <el-table-column label="类型"
                                           width="80">
                            <template #default="scope">
                              <span v-if="scope.row.wptype">{{scope.row.wptype}}</span>
                              <span v-else
                                    style="color:#999">{{'未填写'}}</span>
                            </template>
                          </el-table-column>

                          <el-table-column align="right">
                            <template #default="scope_1">
                              <el-button size="mini"
                                         type="danger"
                                         @click="handleDelete(scope.row.no-1,scope_1.$index, scope_1.row)">移除</el-button>
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>

                    </template>
                  </el-table-column>
                  <!-- =================物品数据end================= -->

                  <el-table-column label="No"
                                   type="index"
                                   width="70">
                    <template #default="scope">
                      <div>
                        {{scope.row.no}}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="长"
                                   width="100">
                    <template #default="scope">
                      <el-input v-model="scope.row.chang"
                                placeholder="cm"></el-input>

                    </template>
                  </el-table-column>
                  <el-table-column width="100"
                                   label="宽">
                    <template #default="scope">
                      <el-input v-model="scope.row.kuan"
                                placeholder="cm"></el-input>
                    </template>
                  </el-table-column>

                  <el-table-column label="高"
                                   width="100">
                    <template #default="scope">
                      <el-input v-model="scope.row.gao"
                                placeholder="cm"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column label="重量"
                                   width="100">
                    <template #default="scope">
                      <el-input v-model="scope.row.weight"
                                placeholder="kg"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column>
                    <template #header>
                      <div class="tableOperation">

                      </div>
                    </template>
                    <template #default='
                                  scope'>
                      <transition name="el-fade-in-linear">
                        <el-button type="danger"
                                   v-if="tableData.length>1"
                                   @click='removeBox(scope.row.no)'>删除</el-button>
                      </transition>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div class="item_btn">
                <el-button type="success"
                           @click="addBox">添加箱</el-button>
                <el-button type="primary"
                           @click="confirmPack">确定打包</el-button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { ObjToArr, throttle } from '../../assets/common'
import { ckPage } from '../../request/api'
export default {
  name: 'start_pack',
  data () {
    return {
      // ------------所有物品类型------------
      allType: '',
      // ------------是否显示设置所有物品类型------------
      isShowSetAllGoodsType: false,
      // ------------是否开启扫码------------
      start_scan: false,
      // ------------快递单号------------
      kdnum: '',
      activeData: '',
      // ------------仓库ID------------
      wid: '',
      resolutionNum: {

      },
      // ------------拆分物品的内容------------
      resolutionData: {
      },
      // ------------是否显示拆分物品数量弹窗------------
      isPopGoods: false,
      // ------------是否拆分物品数量------------
      resolution: false,
      // ------------物品管理显示------------
      goodsManagement_show: false,
      // ------------装箱单数据------------
      tableData: [
        {
          no: 1,
          chang: '',
          kuan: '',
          gao: '',
          weight: '',
          wpinfo: []
        }
      ],
      // ------------物品说明------------
      wpinfo: [],
      // ------------物品说明是否显示------------
      description_show: false,

      // ------------货币类型------------
      currencyType: [],
      // ------------物品类型------------
      goodsType: {

      },
      // ------------父级选择器------------
      fatherSelect: {},
      // ------------默认展开的内容------------
      activeNames: [],
      // ------------弹出层展开的内容------------
      popActiveNames: [],
      // ------------当前选中的物品------------
      activeGoodsInfo: {
        id: ''
      },
      // ------------要打包的数据------------
      dataIten: {},
      // ------------遮罩------------
      fullscreenLoading: false
    }
  },
  computed: {
    //------------最大数量限制------------
    active_max () {
      return this.resolutionNum[this.resolutionData.index] ? this.resolutionNum[this.resolutionData.index].max : 0
    },
    //------------用户选择的物品数量------------
    active_num: {
      get: function () {
        return this.resolutionNum[this.resolutionData.index] ? this.resolutionNum[this.resolutionData.index].num : 0
      },
      set: function (res) {
        if (typeof res == 'number' && this.resolutionNum[this.resolutionData.index]) {
          this.resolutionNum[this.resolutionData.index].num = res
        }
      }
    },
    //   ------------VUEX------------
    numberUnit () {
      return this.$store.state.numberUnit
    },
    currenctyDate () {
      return this.$store.state.currenctyDate
    }
  },
  methods: {
    //------------设置所有物品类型------------
    setAllGoodsType (e) {
      this.fullscreenLoading = true
      for (const key in this.dataIten.zxdinfo) {
        for (const k in this.dataIten.zxdinfo[key]) {
          for (const kk in this.dataIten.zxdinfo[key][k].wpinfo) {
            this.dataIten.zxdinfo[key][k].wpinfo[kk].wptype = e
          }
        }
      }
      this.$message.success('成功')
      this.fullscreenLoading = false
    },
    //------------确定打包------------
    async confirmPack () {
      let falg = true
      for (const key in this.dataIten.zxdinfo) {
        for (const e of this.dataIten.zxdinfo[key]) {
          if (e.wpinfo) {
            e.wpinfo.forEach(() => {
              falg = false
            })
          }
        }
      }
      if (falg) {
        let isProhibited = false
        for (const item of this.tableData) {
          if (item.gao == '' || item.chang == '' || item.weight == '' || item.kuan == '') {
            return this.$message.error(`错误,第${item.no}箱重量体积信息错误`)
          }
          item.wpinfo.forEach(element => {
            element.id = ''
            element.orderid = ''
            if (element.wptype == '') {
              isProhibited = true
            }
          })
        }
        try {
          await this.$confirm(isProhibited ? '您打包的物品中有未设置或设置了禁运品的物品，是否继续?' : '是否核对好了数据,点击确定将会为客户打包', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          })
          const res = await ckPage({
            pd: 'okdabao',
            orderid: this.$route.query.id,
            zxdinfo: JSON.stringify(this.tableData),
            ckid: this.wid
          })
          if (res.errno == 0) {
            this.$message.success(res.message)
            this.$router.go(-1)
          } else {
            this.$message.error(res.message)
          }
        } catch (err) {
          console.log(err);
        }

      } else {
        this.$message.error('还有物品未打包')
      }
    },
    //------------未选择------------
    unselected: throttle(function () {
      this.$message.error('选择包裹后再扫码')
    }, 5000),
    //------------扫码激活选择物品------------
    scanQrGoods (index) {
      if (!this.dataIten.zxdinfo[index]) {
        this.$message.error('错误，该订单没有【' + index + '】的快递')
      }

    },
    //------------还原数据------------
    handleDelete (f_index, index, item) {
      let data = this.tableData[f_index].wpinfo.splice(index, 1)[0];
      let dataArr = item.index.split('-')
      if (!this.dataIten.zxdinfo[dataArr[0]]) {
        this.dataIten.zxdinfo[dataArr[0]] = []
      } else if (!this.dataIten.zxdinfo[dataArr[0]][dataArr[1]]) {
        this.dataIten.zxdinfo[dataArr[0]][dataArr[1]] = {}
      }
      //   只还原数量
      if (this.dataIten.zxdinfo[dataArr[0]][dataArr[1]].wpinfo[dataArr[2]] ? this.dataIten.zxdinfo[dataArr[0]][dataArr[1]].wpinfo[dataArr[2]].id == item.id : false) {
        this.dataIten.zxdinfo[dataArr[0]][dataArr[1]].wpinfo[dataArr[2]].num += item.num
        return
      }

      dataArr[2] = this.dataIten.zxdinfo[dataArr[0]][dataArr[1]].wpinfo.length
      data.index = dataArr.join('-')
      this.dataIten.zxdinfo[dataArr[0]][dataArr[1]].wpinfo.push(data)
    },
    //------------清除数据------------
    closeDataReset () {
      this.$nextTick(() => {
        this.resolutionData = {}
        this.fatherSelect = {}
        this.start_scan = false
        let data = []
        for (const key in this.dataIten.zxdinfo) {
          data.push(key)
          this.popActiveNames.push(key)
          this.fatherSelect[key] = {
            falg: false,
            isIndeterminate: false,
            checkedCities: []
          }
        }
        this.activeNames = data
      })
    },
    //------------确定添加物品------------
    confirmAddGoods () {
      this.tableData[this.activeData].wpinfo
      //   先循环用户选择的数据
      let data = JSON.parse(JSON.stringify(this.dataIten.zxdinfo))
      for (const key in this.fatherSelect) {
        // 循环快递单号下面的数据
        this.fatherSelect[key].checkedCities.forEach(element => {
          let dataArr = element.split('-')
          let testData = true
          // 循环用户选择拆分的数据
          for (const k in this.resolutionNum) {
            //   如果用户拆分数量和剩余数量相等就全部添加进去
            // if (k == element && (this.resolutionNum[k].max == this.resolutionNum[k].num)) {
            // } else
            //   拆分小于总数量
            if (k == element && (this.resolutionNum[k].max > this.resolutionNum[k].num)) {
              //   复制一下数据
              let data = JSON.parse(JSON.stringify(this.dataIten.zxdinfo[dataArr[0]][dataArr[1]].wpinfo[dataArr[2]]))
              data.num = this.resolutionNum[k].num
              data.index = element
              //   先把总数量减少
              this.dataIten.zxdinfo[dataArr[0]][dataArr[1]].wpinfo[dataArr[2]].num -= this.resolutionNum[k].num
              // 数据添加到箱子上去
              this.tableData[this.activeData].wpinfo.push(data)
              // 通知外部不需要循环
              testData = false
            }
          }
          if (testData) {
            for (const key in this.dataIten.zxdinfo[dataArr[0]][dataArr[1]].wpinfo) {
              if (this.dataIten.zxdinfo[dataArr[0]][dataArr[1]].wpinfo[key].id == data[dataArr[0]][dataArr[1]].wpinfo[dataArr[2]].id) {
                let data_2 = this.dataIten.zxdinfo[dataArr[0]][dataArr[1]].wpinfo.splice(key, 1)[0]
                data_2.index = element
                this.tableData[this.activeData].wpinfo.push(data_2)
              }
            }
          }
        });
      }
      this.goodsManagement_show = false
    },
    // ------------确定选择数量------------
    confirmSelecrNum () {
      let num = this.resolutionNum[this.resolutionData.index].num
      if (num == 0) {
        this.$message.error('最低数量不能为0')
      } else {
        this.isPopGoods = false
      }
    },
    // ------------取消选择数量------------
    cancelNum () {
      let index_arr = this.resolutionData.index.split('-')
      let inde_data = this.fatherSelect[index_arr[0]].checkedCities.findIndex((data) => {
        return data == this.resolutionData.index
      })

      this.fatherSelect[index_arr[0]].checkedCities.splice(inde_data, 1)
      this.isPopGoods = false
      this.$nextTick(() => {
        delete this.resolutionNum[this.resolutionData.index]
      })
    },
    // ------------显示物品管理------------
    showGoodsM (e) {
      this.start_scan = true
      this.activeData = e - 1
      this.goodsManagement_show = true
    },
    // ------------删除箱------------
    removeBox (index) {
      this.$confirm('此操作将永久删除该箱, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        this.tableData.splice(index, 1)
        for (const key in this.tableData) {
          this.tableData[key].no = parseInt(key, 10)
        }

        this.$notify({
          type: 'success',
          message: '删除成功!',
          title: '成功'
        });
      }).catch(() => {
        this.$notify({
          title: '已取消',
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // ------------添加箱------------
    addBox () {
      this.tableData.push({
        no: this.tableData.length + 1,
        chang: '',
        width: '',
        gao: '',
        weight: '',
        wpinfo: []
      })
    },
    // ------------显示物品说明------------
    showGoodsExplain () {
      this.description_show = true
    },
    // ------------切换显示的物品详情------------
    tabShowGoodsInfo (e) {
      this.activeGoodsInfo = e
    },

    // ------------Checkbox选择器回调------------
    selectorCollback (e, item, index) {

      if (e && this.resolution) {
        this.resolutionData = item
        this.resolutionNum[index] = {
          num: parseInt(item.num, 10),
          max: parseInt(item.num, 10)
        }
        this.resolutionData.index = index
        this.isPopGoods = true
      } else {
        this.$nextTick(() => {
          if (this.resolutionNum[index]) {
            delete this.resolutionNum[index]
          }
        })
      }
    },
    // ------------group选择器回调------------
    handleCheckedCitiesChange (value, index) {
      // 获取这个快递单号一共有多少物品
      if (!this.fatherSelect[index].goodsNum) {
        let num = 0
        for (const key in this.dataIten.zxdinfo[index]) {

          num += parseInt(this.dataIten.zxdinfo[index][key]?.wpinfo?.length, 10)
        }
        this.fatherSelect[index].goodsNum = num
      }
      // 获取当前选中了多少物品
      let checkedCount = value.length;
      // 开始判断
      if (checkedCount > 0 && checkedCount < this.fatherSelect[index].goodsNum) {
        this.fatherSelect[index].isIndeterminate = true;
        this.fatherSelect[index].falg = false;
      } else if (checkedCount == 0) {
        this.fatherSelect[index].falg = false;
        this.fatherSelect[index].isIndeterminate = false;
      } else if (checkedCount > 0 && checkedCount == this.fatherSelect[index].goodsNum) {
        this.fatherSelect[index].isIndeterminate = false;
        this.fatherSelect[index].falg = true;
      }
    },
    // ------------物品选择回调------------
    handleCheckAllChange (e, i) {
      if (e) {
        this.fatherSelect[i].checkedCities = []
        this.fatherSelect[i].isIndeterminate = false;
        for (const key in this.dataIten.zxdinfo[i]) {
          for (const k in this.dataIten.zxdinfo[i][key].wpinfo) {
            this.fatherSelect[i].checkedCities.push(i + '-' + key + '-' + k)
          }
        }
      } else {
        if (this.resolution) {
          this.$nextTick(() => {
            this.resolutionNum = {}
          })
        }
        this.fatherSelect[i].checkedCities = []
      }
    },
    // ------------禁止事件传递------------
    stopPop () {
    },
    // ------------默认展开所有------------
    unfoldAll () {
      let data = []
      for (const key in this.dataIten.zxdinfo) {
        data.push(key)
        this.popActiveNames.push(key)
        this.fatherSelect[key] = {
          falg: false,
          isIndeterminate: false,
          checkedCities: []
        }
        for (const k in this.dataIten.zxdinfo[key].wpinfo) {
          this.dataIten.zxdinfo[key].wpinfo[k].selectFalg = true
        }
      }
      this.activeNames = data
    },
    // ------------获取打包订单数据------------
    async getOrderData () {
      let res = await ckPage({
        pd: 'getinfo',
        ckid: this.wid,
        orderid: this.$route.query.id
      })
      if (res.errno == 0) {
        this.dataIten = res.orderinfo
        this.unfoldAll()
        this.goodsType = ObjToArr(res.wptype)

      }
    }
  },
  async created () {
    this.fullscreenLoading = true

    //  ------------ 检测是否有数据，无数据就返回------------
    if (this.$route.query.id) {
      this.fullscreenLoading = false
      let wid = JSON.parse(localStorage.getItem('ty_warehouseData'))
      let cklist = JSON.parse(localStorage.getItem('cklist'))
      //  --------- 
      if (cklist) {
        if (wid) {
          this.wid = wid.id
        } else {
          this.wid = cklist.cklist[0].id
        }
      } else {
        await this.$store.dispatch('cklist')
        localStorage.setItem('cklist', JSON.stringify(this.$store.state.warehouse))
        if (wid) {
          this.wid = wid.id
        } else {
          this.wid = cklist.cklist[0].id
        }
      }
      this.getOrderData()
    } else {
      this.$notify.error({
        title: '错误',
        message: '没有检测到打包数据，正在为您跳转打包界面',
        onClose: () => {
          this.fullscreenLoading = false
          this.$router.replace({
            name: 'Pack',
          })
        },
        duration: 2000
      });
    }

    //  ------------处理扫码------------
    //页面加载完执行事件
    let code = "";
    let lastTime;
    let nextTime;
    let lastCode;
    let nextCode;
    //用户按下按键执行
    document.onkeydown = (e) => {
      if (this.start_scan) {
        // 保存按键码
        nextCode = e.which;
        // 设置跳过的特殊字段
        if (nextCode == 16) {
          return
        }

        //保存按键时间
        nextTime = new Date().getTime();
        // 如果按键码不为空 且 上一次按键码也不为空 且 两次按键激活时间小等于30毫秒 
        if ((lastCode != null && lastTime != null && nextTime - lastTime <= 80)) {
          // code值加上当前按下的值并保存
          code += lastCode;
          // 如果两次按键激活时间大于100毫秒
        } else if (lastCode != null && lastTime != null && nextTime - lastTime > 100) {
          // 清除code值
          code = "";
        }
        // 保存这次按键码
        lastCode = e.key;
        // 保存这次按键时间
        lastTime = nextTime;
        //如果用户按下回车
        if (e.which == 13) {
          // 如果当前保存的值大于2位
          if (code.length < 2) {
            //手动输入的时间不会让code的长度大于2，所以这里只会对扫码枪有效
            return false;
          }
          this.kdnum = code //要更改的值变成扫码枪的值 this.queryCondition.kdnum可以改成需要更改的值
          this.scanQrGoods(code)
          code = ""; //清空值
        }
      }

    }
  }
}
</script>

<style lang='scss'>
.start_pack {
  height: 100%;
  width: 100%;
  padding: 0 20px 20px 20px;
  box-sizing: 10px;
  box-sizing: border-box;
  background-color: #409eff;
  .pop {
    .dialog_title {
      font-size: 18px;
      display: flex;
      justify-content: space-between;
      padding-right: 40px;
      align-items: center;
      .dialog_right {
        // width: 20px;
        // height: 20px;
        // background-color: pink;
      }
    }
  }
  .goodsManagement {
    height: 700px;
    overflow: auto;
    .pop_collapse_view {
      .el-checkbox-group {
        display: flex;
        .el-checkbox {
          margin-right: 20px;
        }
      }
    }
  }
  .start_pack_view {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .view_top,
    .bottom_left,
    .bottom_right {
      background-color: #fff;
      flex: 1;
      border-radius: 4px;
      box-sizing: border-box;
      padding: 10px;

      .top_title {
        flex: 1;
        height: 50px;
        color: #333;
        display: flex;
        font-size: 20px;
        max-height: 50px;
        min-height: 50px;
        letter-spacing: 1px;
        justify-content: space-between;
      }
    }
    .view_top {
      width: 100%;
      min-height: 180px;
      display: flex;
      flex-direction: column;
      //   align-items: center;
      //   flex-direction: ;
      .el-form {
        flex: 3;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        transition: all 0.3s;
        background-color: #fff;

        .el-form-item {
          width: 18%;
          margin-right: 2%;
          margin-bottom: 0;
          //   display: flex;
          //   align-items: center;
        }
      }
    }
    .view_bottom {
      flex: 4;
      height: 0;
      display: flex;
      margin-top: 10px;
      .bottom_left {
        flex: 1;
        height: 100%;
        display: flex;
        margin-right: 5px;
        flex-direction: column;
        .top_title {
          //   height: 20px !important;
        }
        .activeGoods {
          color: #409eff !important;
        }
        .left_bottom {
          flex: 1;
          max-height: 80px;
          margin-top: 10px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
        .left_content {
          flex: 1;
          padding: 10px;
          overflow: auto;
          margin-top: 20px;
          border: 1px solid #eee;
          .el-collapse {
            border-top: 0;
          }
          .el-collapse-item__content {
            padding-left: 20px;
          }
          .collapse_title {
            color: #999 !important;
          }

          .collapse_view {
            // background-color: #e0e0e0;
            display: flex;
            .collapse_view_item:hover {
              color: #409eff;
            }
            .collapse_view_item {
              margin-right: 10px;
              cursor: pointer;
              font-weight: 700;
              color: #333;
            }
          }
        }
      }
      .bottom_right {
        flex: 1;
        overflow: auto;
        margin-left: 5px;
        display: flex;
        flex-direction: column;

        .right_content {
          flex: 9;
          width: 100%;
          //   background-color: pink;
          .packing_list {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            .item_btn {
              flex: 1;
              max-height: 80px;
              //   background-color: pink;
              display: flex;
              padding: 0 10px;
              justify-content: space-between;
              align-items: center;
            }
            .list_item {
              width: 100%;
              flex: 1;
              border-radius: 10px;
              margin-bottom: 10px;
              .expand_btn {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                margin-bottom: 10px;
              }
              .expand_view {
                width: 100%;
                // height: 200px;
                // background-color: #eee;
                // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                border: 1px solid #4c90fd;
                border-radius: 2px;
              }
              .tableOperation {
                display: flex;
                justify-content: flex-end;
              }
            }
          }
        }
      }
    }
  }
}
</style>